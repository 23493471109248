<template>
    <div
        class="full-height pa-10-20"
    >
        <h6>{{ program.name }}</h6>

        <Search
            class="mt-10 box-ddd"
            :search="search"
            :option="search_option"

            @click="getSearch"
            @reset="reset"
            @setSearchDateType="setSearchDateType"
        >
            <select
                slot="add"
                class="pa-5-10 mr-10"

                v-model="search.state"
                @change="getSearch(1)"
            >
                <option
                    v-for="add in codes.quick_payment_state_select"
                    :key="'status_' + add.code"
                    :value="add.code"
                >{{ add.name }}
                </option>
            </select>
        </Search>

        <div
            class="mt-10 box-ddd"
        >
            <div
                class="pa-10 bg-identify"
            >
                {{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}
            </div>
            <div
                v-if="summary.length > 0"
                class=" bg-white justify-space-between"
            >
                <div class="pa-20 flex-1 bg-icon-paper border-right">
                    <div class="color-identify">{{ summary[0].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[0].count | makeComma }}건 /
                        {{ summary[0].amount | makeComma }}원
                    </div>
                </div>

                <div class="pa-20 flex-1 bg-icon-paper-cancel border-right">
                    <div class="color-identify">{{ summary[1].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[1].count | makeComma }}건 /
                        {{ summary[1].amount | makeComma }}원
                    </div>
                </div>

                <div class="pa-20 flex-1 bg-icon-calc">
                    <div class="color-identify">{{ summary[2].name }}</div>
                    <div class="mt-10 font-weight-bold size-px-16"> {{ summary[2].count | makeComma }}건 /
                        {{ summary[2].amount | makeComma }}원
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10 pa-10 bg-white flex-column ">
            <div class="text-right">
                <button
                    class="box mr-10 pa-4-10 size-px-12"
                    @click="toExcel"
                >
                    <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                    <span class="vertical-middle">엑셀 다운로드</span></button>
                <select
                    class="pa-5-10 mr-10 size-px-12"
                    v-model="search.size"
                    @change="getSearch(1)"
                >
                    <option
                        v-for="cnt in codes.list_cnt"
                        :key="'cnt_' + cnt"
                        :value="cnt"
                    >{{ cnt }} 건씩 보기
                    </option>
                </select>
            </div>
            <table
                v-if="items.length > 0"
                class="mt-10 table table-even"
            >
                <colgroup>

                    <col width="60px"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>
                    <col width="auto"/>

                    <col width="100px"/>
                </colgroup>
                <thead>
                <tr>
                    <th>NO</th>
                    <th>결제일</th>
                    <th>총판명</th>
                    <th>지사명</th>
                    <th>대리점명</th>

                    <th>리셀러명</th>
                    <th>가맹점명</th>
                    <th>카드번호</th>
                    <th>할부개월</th>
                    <th>결제금액</th>

                    <th>정산금액</th>
                    <th>수수료율</th>
                    <th>지급주기</th>
                    <th>결제취소</th>
                    <th>결제상태</th>

                    <th>상세</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in item_list"
                    :key="'list_' + index"
                >
                    <td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
                    <td>{{ item.paymentDate }}</td>
                    <td class="text-left">{{ item.branchName }}</td>
                    <td class="text-left">{{ item.distributorName ? item.distributorName : '-' }}</td>
                    <td class="text-left">{{ item.agencyName ? item.agencyName : '-' }}</td>
                    <td class="text-left">{{ item.resellerName ? item.resellerName : '-' }}</td>
                    <td class="text-left">{{ item.shopName }}</td>
                    <td>{{ item.cardNumber }}</td>
                    <td>
                        <span v-if="item.installment === 0">일시불</span>
                        <span v-else>{{ item.installment }}개월</span>
                    </td>
                    <td class="text-right">{{ item.paymentAmount | makeComma }}원</td>
                    <td class="text-right">{{ item.chargeAmount | makeComma }}원</td>
                    <td>{{ item.totalFee | makeComma }} %</td>
                    <td>
                        <span v-if="item.quickType">D-{{ item.quickType }}</span>
                        <span v-else>-</span>
                    </td>
                    <td>{{ item.cancelDate }}</td>
                    <td :class="'color-' + item.state_color">{{ item.state_name }}</td>
                    <td>
                        <button
                            class="bg-identify pa-5-10 size-px-12"
                            @click="toDetail(item)"
                        >상세보기
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-else
                class="full-height flex-column justify-center mt-10 top-line-identify"
            >
                <div class="text-center pa-20">
                    <v-icon
                        class="size-px-48 "
                    >mdi mdi-cloud-off-outline
                    </v-icon>
                    <br/>
                    <br/>
                    <div class="font-weight-bold size-px-24">No Data</div>
                </div>
            </div>

        </div>
        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

        <Excel
            v-if="is_excel"
            :excel_data="excel_data"

            @finish="is_excel = !is_excel"
        ></Excel>

        <Modal
            :is_modal="modal_cancel"
            :top="true"
            :bottom="true"

            title="배달비 결제 취소"
            content="해당 배달비 결제 내역을 취소하시겠습니까?"
            width="350px"
            content_class="ptb-30"

            @close="close"
            @click="cancel"
            @cancel="close"
        ></Modal>
        <Modal
            :is_modal="fail_cancel"
            :top="true"
            :bottom="true"
            :option="cancel_modal_option"

            title="배달비 결제 취소 실패"
            :content="`취소 불가, 취소 필요 시 홀빅 T: 1877-5916 문의하여 주시기 바랍니다.`"
            width="350px"
            content_class="pa-20"

            @close="close"
            @click="close"
        ></Modal>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"

            @close="close"
        >

            <QuickDetail
                slot="modal-content"
                :item="item"
                @checked="setCancelCheck"
            ></QuickDetail>
            <div
                slot="modal-bottom"
                class="pa-10 justify-center"
            >
                <button
                    v-if="item.canCancel == 1"
                    @click="isCancel"
                    class="pa-10 bg-identify-outline mr-10 "
                >결제취소
                </button>
                <button
                    class="pa-10 bg-identify"
                    @click="close"
                >확인
                </button>
            </div>
        </Modal>
    </div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import QuickDetail from "@/view/Quick/QuickDetail";

export default {
    name: 'QuickList'
    , components: {QuickDetail, Modal, Excel, Search, Pagination}
    , data: function () {
        return {
            user: [],
            program: {
                name: '배달비 결제'
                , top: true
                , title: true
                , bottom: false
            }
            , search: {
                route: this.$route.name
                , page: this.$route.query.page ? this.$route.query.page : 1
                , size: this.$route.query.size ? this.$route.query.size : 10
                , total_count: 0
                , search_type: this.$route.query.search_type ? Number(this.$route.query.search_type) : ''
                , keywordType: ''
                , keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
                , state: ''
                , sDate: this.$route.query.sDate ? this.$route.query.sDate : ''
                , eDate: this.$route.query.eDate ? this.$route.query.eDate : ''
                , startDate: this.$route.query.sDate ? this.$route.query.sDate : ''
                , endDate: this.$route.query.eDate ? this.$route.query.eDate : ''
                , search_date_type: this.$route.query.search_date_type ? this.$route.query.search_date_type : 'weekly'
                , maxAmount: ''
                , minAmount: ''
            }
            , search_option: {
                sDate: true
                , eDate: true
                , search_type: [
                    {name: '아이디', column: 1},
                    {name: '영업점명', column: 2},
                    {name: '가맹점명', column: 3},
                    {name: '결제금액', column: 4},
                    {name: '카드번호(앞4자리)', column: 5},
                    {name: '승인번호', column: 6},
                ]
                , is_search_date_type: true
            }
            , is_excel: false
            , excel_data: {
                name: '배달비 결제 내역'
                , header: [ // api 추가 필요
                    {key: 0, name: 'NO', column: 'idx'}
                    , {key: 0, name: '결제일시', column: 'approvalDate'}
                    , {key: 0, name: '결제취소', column: 'cancelDate'}
                    , {key: 0, name: '총판명', column: 'branchName'}
                    , {key: 0, name: '지사명', column: 'distributorName'}
                    , {key: 0, name: '대리점명', column: 'agencyName'}
                    , {key: 0, name: '리셀러명', column: 'resellerName'}
                    , {key: 0, name: '가맹점명', column: 'shopName'}
                    , {key: 0, name: '카드사', column: 'cardName'}
                    , {key: 0, name: '카드번호', column: 'cardNumber'}
                    , {key: 0, name: '승인번호', column: 'approvalNumber'}
                    , {key: 0, name: '할부', column: 'installment'}
                    , {key: 0, name: '결제금액', column: 'approvalAmount'}
                    , {key: 0, name: '취소금액', column: 'cancelAmount'}
                    , {key: 0, name: '수수료율', column: 'feeRate'}
                    , {key: 0, name: '수수료', column: 'feeAmount'}
                    , {key: 0, name: '부가세VAT', column: 'vat'}
                    , {key: 0, name: '정산지급금', column: 'chargeAmount'}
                    , {key: 0, name: '결제상태', column: 'state'}
                ]
                , content: null
            }
            , items: []
            , item: {}
            , summary: []
            , is_modal: false
            , modal_option: {
                title: '배달비 결제 상세'
                , top: true
                , bottom: true
                , slot_bottom: true
            }
            , modal_cancel: false
            , cancelCheck: false
            , fail_cancel: false
            , cancel_modal_option: {
                title: '배달비 결제 취소 실패'
                , top: true
                , bottom: true
                , is_cancel: true
            }
        }
    }
    , computed: {
        item_list: function () {

            return this.items.filter((item) => {
                for (let i = 0; i < this.codes.quick_payment_state.length; i++) {
                    if (item.quickState == this.codes.quick_payment_state[i].code) {
                        item.state_name = this.codes.quick_payment_state[i].name
                        item.state_color = this.codes.quick_payment_state[i].color
                        break
                    }
                }

                if (!item.cancelDate) {
                    item.cancelDate = '-'
                }

                return item
            })
        }
    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)
                if (this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'quick/payment'
                    , data: this.search
                })

                if (result.success) {
                    console.log(result.data)
                    this.items = result.data.tableList.data
                    this.search.total_count = result.data.tableList.totalCount
                    this.summary = result.data.statistics
                    await this.getExcelData()

                    this.$storage.setQuery(this.search)
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , getExcelData: async function () {
            this.search.keywordType = this.search.search_type
            try {
                this.$layout.onLoading()
                console.log(this.search.sDate)
                console.log(this.search.eDate)
                this.$set(this.search, 'startDate', this.search.sDate)
                this.$set(this.search, 'endDate', this.search.eDate)

                let result = await this.$Axios({
                    method: 'get'
                    , url: 'quick/payment/excel'
                    , data: this.search
                })

                if (result.success) {
                    console.log(result.data.data, 'excel')
                    this.excel_data.content = result.data.data
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , toDetail: async function (item) {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'quick/payment/' + item.idx
                })

                if (result.success) {
                    this.item = result.data
                    if (this.item.state === '4') {
                        this.modal_option.title = '배달비 결제실패'
                    } else if (this.item.state === '3') {
                        this.modal_option.title = '배달비 취소실패'
                    } else if (this.item.state === '2') {
                        this.modal_option.title = '배달비 결제취소'
                    } else if (this.item.state === '1') {
                        this.modal_option.title = '배달비 취소요청'
                    } else {
                        this.modal_option.title = '배달비 결제완료'
                    }
                    console.log(this.item)
                    this.is_modal = true
                } else {
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
            } finally {
                this.$layout.offLoading()
            }
        }
        , reset: function () {
            this.search = {
                route: this.$route.name
                , page: 1
                , size: 10
                , total_count: 0
                , search_type: ''
                , keyword: ''
                , state: ''
                , sDate: this.date.getLastDate(this.date.getToday('-'), 7, '-')
                , eDate: this.date.getToday('-')
                , search_date_type: 'weekly'
                , maxAmount: ''
                , minAmount: ''
            }

            this.getData()
        }
        , getSearch: function (page) {

            if (page) {
                this.search.page = page
            }

            //this.$layout.push( { name: this.$route.name, params: this.$route.params, query: this.search})

            this.getData()
        }

        , toExcel: function () {
            this.is_excel = true
        }
        , setSearchDateType: function (type) {
            this.search.page = 1
            this.search.search_date_type = type
        }
        , close: function () {
            this.is_modal = false
            this.modal_cancel = false
            this.fail_cancel = false
        }
        , cancel: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'put'
                    , url: 'quick/payment/' + this.item.idx
                })

                if (result.success) {
                    this.$layout.setNotify({type: 'success', message: result.message})
                    this.close()
                    this.getSearch()
                } else {
                    this.fail_cancel = true
                    this.$layout.setNotify({type: 'error', message: result.message})
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setCancelCheck: function (check) {
            this.cancelCheck = check
        }
        , isCancel: function () {
            if (!this.cancelCheck) {
                this.$layout.setNotify({type: 'error', message: '결제 취소 시 확인 사항을 체크해 주세요.'})
                return
            }
            this.close()
            this.modal_cancel = true
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        this.getData()
    }
    , watch: {
        'search.search_date_type': {
            immediate: true
            , handler: function (call) {
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
                let today = this.date.getToday('-')
                if(this.search.search_date_type !== 'free') {
                    this.search.eDate = today
                }
                switch (call) {
                    case 'today':
                        this.$set(this.search, 'sDate', today)

                        this.getData()
                        break
                    case 'weekly':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 7, '-'))

                        this.getData()
                        break
                    case 'month':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 30, '-'))

                        this.getData()
                        break
                    case '3':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 90, '-'))
                        break
                    case '6':
                        this.$set(this.search, 'sDate', this.date.getLastDate(today, 180, '-'))
                        break
                }
            }
        }
    }
}
</script>

<style>
.bg-icon-paper {
    background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-paper-cancel {
    background: url('../../assets/images/icon/icon-paper-cancel.svg') no-repeat 10px center;
    padding-left: 70px;
}

.bg-icon-calc {
    background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center;
    padding-left: 70px;
}
</style>